import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { QnaSearchKeywordList } from '@/types/qna';
import { localStorageEffect } from '@/utils/atom';

export const realTimeAnswerState = atom<boolean>({
  key: 'realtimeAnswerState',
  default: false,
  effects: [localStorageEffect('realtime')],
});

const { persistAtom } = recoilPersist();

export const recentKeywordListState = atom<QnaSearchKeywordList>({
  key: 'recentKeywordListState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});
