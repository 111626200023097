/* eslint-disable @typescript-eslint/no-var-requires */
import { FileWithBlobUrl } from '@/types/common';
import { BFF_BASE_URL } from '@/utils/fetcher';

interface Options {
  /** @default Number.POSITIVE_INFINITY */
  maxSizeMB?: number;
  /** @default undefined */
  maxWidthOrHeight?: number;
  /** @default true */
  useWebWorker?: boolean;
  /** @default 10 */
  maxIteration?: number;
  /** Default to be the exif orientation from the image file */
  exifOrientation?: number;
  /** A function takes one progress argument (progress from 0 to 100) */
  onProgress?: (progress: number) => void;
  /** Default to be the original mime type from the image file */
  fileType?: string;
  /** @default 1.0 */
  initialQuality?: number;
  /** @default false */
  alwaysKeepResolution?: boolean;
}

export const convertHeic = async (file: File) => {
  const heic2any = (await import('heic2any')).default;
  const blob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
    quality: 0.8,
  });
  const newImage = new File([blob as Blob], file.name.split('.')[0] + '.jpeg', {
    type: 'image/jpeg',
    lastModified: new Date().getTime(),
  });
  return newImage;
};

export const convertedHeicSingleImage = async (originFile: File) => {
  if (originFile.type.includes('heic')) {
    return await convertHeic(originFile);
  }

  return originFile;
};

export const setImageUrl = async (file: File): Promise<FileWithBlobUrl> => {
  if (file.type.includes('heic')) {
    const convertedImage = await convertHeic(file);
    return Object.assign(convertedImage, {
      blobUrl: URL.createObjectURL(convertedImage),
    });
  } else {
    return Object.assign(file, {
      blobUrl: URL.createObjectURL(file),
    });
  }
};

export const onCompressImage = async (file: File, options?: Options) => {
  const imageCompression = (await import('browser-image-compression')).default;
  const blob = await imageCompression(file, { ...options });

  const compressedImage = new File(
    [blob as Blob],
    file.name.split('.')[0] + '.jpeg',
    {
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    },
  );
  return compressedImage;
};

interface ResizeImgProps {
  originUrl: string;
  width: number;
  height: number;
  quality?: number;
}

export const resizingImg = ({
  originUrl,
  width,
  height,
  quality = 75,
}: ResizeImgProps) => {
  return `${originUrl}?w=${width}&h=${height}&q=${quality}`;
};

export const isImage = (image: File) => {
  return image.type.includes('image');
};

export const redirectPostImage = (boardPostAttachmentId: string) => {
  return `${BFF_BASE_URL}/api/v1/board/board-post-attachments/${boardPostAttachmentId}/redirect-to-signed-url`;
};
