import { throttle } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { getMobileWebPageName, getPageName } from '@/utils/logger';
import {
  NATIVE_POST_MESSAGE_TYPE,
  isWebView,
  sendPostMessageToNative,
} from '@/utils/mobile/RNPlugins';

interface Props {
  webLink?: string;
  as?: string;
  mobileScreenName: string;
  // params?: { [key: string]: string | number | boolean | null };
  params?: any;
  type?: NATIVE_POST_MESSAGE_TYPE;
}
const useCustomRouter = () => {
  const router = useRouter();
  const moveToPage = useCallback(
    ({
      webLink,
      mobileScreenName,
      params,
      as,
      type = NATIVE_POST_MESSAGE_TYPE.MOVE_TO_PAGE,
    }: Props): void => {
      if (isWebView()) {
        sendPostMessageToNative(type, {
          screenName: mobileScreenName,
          params: params
            ? {
                ...params,
              }
            : undefined,
        });
      } else {
        if (!webLink) return;
        if (type === NATIVE_POST_MESSAGE_TYPE.REPLACE_TO_PAGE) {
          router.replace(webLink, as);
          return;
        }
        router.push(webLink, as);
      }
    },
    [router],
  );

  const getCustomPageName = useCallback(
    (defaultPageName: string) => {
      return isWebView() ? defaultPageName : getMobileWebPageName(router);
    },
    [router],
  );

  const pageName = getPageName(router);

  return {
    router,
    moveToPage: throttle(moveToPage, 1500, { trailing: false }),
    getCustomPageName,
    pageName,
  };
};

export default useCustomRouter;
