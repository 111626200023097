import * as Types from '../../types/bff/gql-schema';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { gqlFetcher } from '@/utils/fetcher';

export const GetAllHomeBannersV2Document = `
    query GetAllHomeBannersV2($request: HomeBannerRequestInput!) {
  allHomeBannersV2(request: $request) {
    id
    backgroundColor
    title
    titleColor
    content
    contentColor
    imageUrls {
      type
      url
    }
    actionUrls {
      type
      url
    }
  }
}
    `;
export const useGetAllHomeBannersV2Query = <
      TData = Types.GetAllHomeBannersV2Query,
      TError = Types.GraphqlError
    >(
      variables: Types.GetAllHomeBannersV2QueryVariables,
      options?: UseQueryOptions<Types.GetAllHomeBannersV2Query, TError, TData>
    ) =>
    useQuery<Types.GetAllHomeBannersV2Query, TError, TData>(
      ['GetAllHomeBannersV2', variables],
      gqlFetcher<Types.GetAllHomeBannersV2Query, Types.GetAllHomeBannersV2QueryVariables>(GetAllHomeBannersV2Document, variables),
      options
    );

useGetAllHomeBannersV2Query.getKey = (variables: Types.GetAllHomeBannersV2QueryVariables) => ['GetAllHomeBannersV2', variables];
;

useGetAllHomeBannersV2Query.fetcher = (variables: Types.GetAllHomeBannersV2QueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetAllHomeBannersV2Query, Types.GetAllHomeBannersV2QueryVariables>(GetAllHomeBannersV2Document, variables, options);