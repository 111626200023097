import dayjs from 'dayjs';
import showdown from 'showdown';

import {
  GetMagazineDetailQuery,
  GetMagazineRelatedMagazinesQuery,
  GetMagazineTagListPageQuery,
  GetMagazinesQuery,
  HospitalOperatingStatus,
} from '@/types/bff/gql-schema';
import { ROUTES } from '@/types/common';
import {
  HomeMagazineItemVo,
  LdFaqJson,
  MagazineAuthor,
  MagazineBottomButtonType,
  MagazineDetailVo,
  MagazineLineItemVo,
  MagazineListItemDto,
  MagazineTagListVo,
  MagazineTagType,
  RelatedMagazinesVo,
} from '@/types/magazine';

type MagazineQueryType = GetMagazinesQuery['magazineCursorPage']['elements'][0];
export const parseMagazineHomeNewest = (
  elements: Array<MagazineQueryType>,
): Array<MagazineLineItemVo> => {
  if (!elements) {
    return [];
  }

  return elements.map((magazine) => ({
    title: magazine.title,
    mobileTitle: magazine.mobileTitle,
    image: magazine.titleImageUrl,
    date: `${dayjs(magazine.createdAt).format('YYYY.MM.DD')}`,
    magazinePid: magazine.magazinePid,
    description: magazine.description,
    mobileDescription: magazine.mobileDescription,
  }));
};

export const parseMagazineListItem = (magazine: MagazineListItemDto) => {
  return {
    ...magazine,
    magazinePid: magazine.magazinePid,
    date: `${dayjs(magazine.createdAt).format('YYYY.MM.DD')}`,
  };
};

export const parseMagazineTagList = (
  data?: GetMagazineTagListPageQuery,
): Array<MagazineTagListVo> => {
  if (data) {
    return data.tagMagazineCursorPage.elements.map((magazine) => {
      return parseMagazineListItem(magazine as MagazineListItemDto);
    });
  }
  return [];
};

export const parseRelatedMagazines = (
  data?: GetMagazineRelatedMagazinesQuery,
): Array<RelatedMagazinesVo> => {
  if (data) {
    return data.magazineRelatedMagazines.map((magazine) => {
      return {
        ...parseMagazineListItem(magazine as MagazineListItemDto),
        contentType: magazine.contentType,
        isArticle: magazine.contentType === 'ARTICLE',
      };
    });
  }

  return [];
};

export const parseMagazineDetail = (
  data: GetMagazineDetailQuery,
): MagazineDetailVo => {
  return {
    ...data.magazineV2,
    author: data.magazineV2.author as unknown as MagazineAuthor,
    bottomButtonType: data.magazineV2
      .bottomButtonType as unknown as MagazineBottomButtonType,
    tags: data.magazineV2.tags.map((tag) => ({
      ...tag,
      tagType: tag.tagType as unknown as MagazineTagType,
    })),
    isExternalWriter: !!data.magazineV2.hospital,
    hospitalName: data.magazineV2.hospital?.name ?? '',
    hospitalPid: data.magazineV2.hospital?.publicId ?? '',
    hospitalAddress: data.magazineV2.hospital?.roadAddress ?? '',
    isHospitalBookable: data.magazineV2.hospital?.isBookable ?? false,
    hospitalProfileImageUrl:
      data.magazineV2.hospital?.profileImages &&
      data.magazineV2.hospital?.profileImages.length > 0
        ? data.magazineV2.hospital.profileImages[0].url
        : undefined,
    hospitalOperatingStatus:
      data.magazineV2.hospital?.todayDuty.operatingStatus ??
      HospitalOperatingStatus.Unknown,
    banner: data.magazineV2.banner ? data.magazineV2.banner : undefined,
  };
};

export const parseHomeMagazines = (
  data?: Array<MagazineQueryType>,
): Array<HomeMagazineItemVo> => {
  if (!data) {
    return [];
  }

  return data.map((magazine) => {
    return {
      title: magazine.title,
      image: magazine.titleImageUrl,
      date: `${dayjs(magazine.createdAt).format('YYYY.MM.DD')}`,
      magazinePid: magazine.magazinePid,
    };
  });
};

export const createFaqLdJson = (
  faq?: MagazineDetailVo,
): LdFaqJson | undefined => {
  if (!faq) {
    return;
  }

  const converter = new showdown.Converter();
  const convertedText = converter.makeHtml(faq.content);

  return {
    '@context': 'https://schema.org',
    '@type': 'QAPage',
    mainEntity: {
      '@type': 'Question',
      name: faq.title,
      text: faq.description,
      dateCreated: dayjs(faq.createdAt).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      answerCount: 1,
      upvoteCount: faq.likeCount,
      author: {
        '@type': 'Organization',
        name: '닥터나우',
        url: `${process.env.NEXT_PUBLIC_DOMAIN}`,
      },
      acceptedAnswer: {
        '@type': 'Answer',
        text: convertedText,
        dateCreated: dayjs(faq.createdAt).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        upvoteCount: faq.likeCount,
        url: `${process.env.NEXT_PUBLIC_DOMAIN}/content/faq/${faq.magazinePid}`,
        author: {
          '@type': 'Organization',
          name: '닥터나우',
          url: `${process.env.NEXT_PUBLIC_DOMAIN}`,
        },
      },
    },
  };
};

export const createMagazineDetailLdJson = (
  magazineDetail?: MagazineDetailVo,
) => {
  if (!magazineDetail) {
    return {};
  }

  const converter = new showdown.Converter();
  const convertedText = converter.makeHtml(magazineDetail?.content || '');

  const LdJson = [
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: '닥터나우',
      url: 'https://doctornow.co.kr',
      logo: 'https://doctornow-front-images.s3.ap-northeast-2.amazonaws.com/og-main.png',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          email: 'contact@doctornow.co.kr',
          contactType: 'customer service',
        },
      ],
    },
    {
      '@context': 'https://schema.org',
      '@type': 'Article',
      image: [magazineDetail?.titleImageUrl],
      headline: magazineDetail?.title,
      name: magazineDetail?.title,
      articleBody: convertedText,
      author: {
        '@type': 'Organization',
        name: '닥터나우',
        url: 'https://doctornow.co.kr',
        logo: {
          '@type': 'ImageObject',
          url: `${process.env.NEXT_PUBLIC_QNA_OG_IMAGE}`,
        },
      },
      url: `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.MAGAZINE.DETAIL.path}/${magazineDetail.magazinePid}`,
      datePublished: dayjs(magazineDetail?.createdAt).format(
        'YYYY-MM-DDTHH:mm:ss[Z]',
      ),
      publisher: {
        '@type': 'Organization',
        name: '닥터나우',
        logo: {
          '@type': 'ImageObject',
          url: `${process.env.NEXT_PUBLIC_QNA_OG_IMAGE}`,
        },
      },
    },
  ];

  return LdJson;
};

export const createNewestMagazineLdJson = (
  magazines: Array<MagazineLineItemVo>,
  faqs: Array<MagazineLineItemVo>,
) => {
  const magazineItems = magazines.map((magazine, i) => ({
    '@type': 'Article',
    position: `${i + 1}`,
    headline: magazine.title,
    image: magazine.image,
    url: `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.MAGAZINE.DETAIL.path}/${magazine.magazinePid}`,
    datePublished: dayjs(magazine.date).format('YYYY-MM-DDTHH:mm:ss[Z]'),
    author: {
      '@type': 'Organization',
      name: '닥터나우',
      url: `${process.env.NEXT_PUBLIC_DOMAIN}`,
    },
  }));

  const faqItems = faqs.map((faq, i) => ({
    '@type': 'Question',
    position: `${magazineItems.length + i + 1}`,
    name: faq.title,
    description: faq.description,
    url: `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.FAQ.DETAIL.path}/${faq.magazinePid}`,
    dateCreated: dayjs(faq.date).format('YYYY-MM-DDTHH:mm:ss[Z]'),
  }));

  const itemListElement = [...magazineItems, ...faqItems];

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    name: '건강 매거진 - 닥터나우',
    description:
      '건강에 대한 궁금증을 닥터나우만의 전문 콘텐츠로 해결해 보세요.',
    url: `${process.env.NEXT_PUBLIC_DOMAIN}${ROUTES.MAGAZINE.HOME.path}`,
    itemListElement,
  };
};
