import * as Types from '../../types/bff/gql-schema';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { gqlFetcher } from '@/utils/fetcher';

export const GetBannerAdExchangeDocument = `
    query GetBannerAdExchange($request: BannerAdExchangeRequestInput!) {
  getBannerAdExchange(request: $request) {
    bannerImageUrl
    bannerAdId
    clickAction {
      clickActionType
      outlink
      phoneNumber
    }
    colorTheme
    targetPlace
    deliberatedCode
    title
    subTitle
    longTitle
    longSubTitle
  }
}
    `;
export const useGetBannerAdExchangeQuery = <
      TData = Types.GetBannerAdExchangeQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetBannerAdExchangeQueryVariables,
      options?: UseQueryOptions<Types.GetBannerAdExchangeQuery, TError, TData>
    ) =>
    useQuery<Types.GetBannerAdExchangeQuery, TError, TData>(
      ['GetBannerAdExchange', variables],
      gqlFetcher<Types.GetBannerAdExchangeQuery, Types.GetBannerAdExchangeQueryVariables>(GetBannerAdExchangeDocument, variables),
      options
    );

useGetBannerAdExchangeQuery.getKey = (variables: Types.GetBannerAdExchangeQueryVariables) => ['GetBannerAdExchange', variables];
;

useGetBannerAdExchangeQuery.fetcher = (variables: Types.GetBannerAdExchangeQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBannerAdExchangeQuery, Types.GetBannerAdExchangeQueryVariables>(GetBannerAdExchangeDocument, variables, options);
export const SaveAdExposureTraceDocument = `
    mutation SaveAdExposureTrace($request: AdExposureHandleMutationRequestInput!) {
  handleAdExposure(request: $request)
}
    `;
export const useSaveAdExposureTraceMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SaveAdExposureTraceMutation, TError, Types.SaveAdExposureTraceMutationVariables, TContext>) =>
    useMutation<Types.SaveAdExposureTraceMutation, TError, Types.SaveAdExposureTraceMutationVariables, TContext>(
      ['SaveAdExposureTrace'],
      (variables?: Types.SaveAdExposureTraceMutationVariables) => gqlFetcher<Types.SaveAdExposureTraceMutation, Types.SaveAdExposureTraceMutationVariables>(SaveAdExposureTraceDocument, variables)(),
      options
    );
useSaveAdExposureTraceMutation.fetcher = (variables: Types.SaveAdExposureTraceMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SaveAdExposureTraceMutation, Types.SaveAdExposureTraceMutationVariables>(SaveAdExposureTraceDocument, variables, options);