import { GetDoctorsByKeywordQuery } from '@/types/bff/gql-schema';
import {
  DoctorSearchResultVo,
  EncyclopediaSearchedDiseaseVo,
  RenderEncyclopediaSearchTag,
} from '@/types/search';

export class HomeSearchService {
  static parseDoctorSearchResult(
    data: Array<GetDoctorsByKeywordQuery>,
  ): Array<DoctorSearchResultVo> {
    if (data) {
      return this.doctorSearchResultParser({
        getDoctorsByKeyword: {
          elements: data.flatMap((page) => page.getDoctorsByKeyword.elements),
          pivot: data[data.length - 1].getDoctorsByKeyword.pivot,
        },
      });
    }
    return [];
  }

  static doctorSearchResultParser(
    data: GetDoctorsByKeywordQuery,
  ): Array<DoctorSearchResultVo> {
    return data.getDoctorsByKeyword.elements.map((doctor) => {
      return {
        ...doctor,
        doctorId: Number(doctor.id),
        profileUrl: doctor.profileUrl,
        doctorName: doctor.doctorName,
        hospitalName: doctor.hospitalName,
        reviewCount: Number(doctor.reviewCount),
        reviewAverageRating: doctor.reviewAverageRating,
        isAbsent: doctor.isAbsent,
        maxWaitingCount: doctor.maxWaitingCount,
        readyForVideoCall: doctor.readyForVideoCall,
        professionalDepartment: {
          doctorId: Number(doctor.id),
          professionalDepartmentId: Number(
            doctor.professionalDepartment.professionalDepartmentId,
          ),
          professionalDepartmentName:
            doctor.professionalDepartment.professionalDepartmentName ?? '',
        },
        diagnosisCount: {
          doctorId: Number(doctor.id),
          count: Number(doctor.diagnosisCount?.count ?? 0),
        },
      };
    });
  }

  static getNewKeywordList(
    originalKeywordList: Array<string>,
    keyword: string,
  ): Array<string> {
    const keywordIdx = originalKeywordList.indexOf(keyword);
    if (keywordIdx !== -1) {
      originalKeywordList.splice(keywordIdx, 1);
    }
    originalKeywordList.unshift(keyword);
    if (originalKeywordList.length > 5) {
      originalKeywordList = originalKeywordList.slice(0, 5);
    }
    return [...originalKeywordList];
  }

  static getEncyclopediaTagListToRender(
    content: EncyclopediaSearchedDiseaseVo,
  ): Array<RenderEncyclopediaSearchTag> {
    const ENCYCLOPEDIA_SEARCH_RESULT_TAG_LIST = [
      {
        label: '질병 정보',
        key: 'information',
        url: '',
      },
      {
        label: '주요 증상은?',
        key: 'general-symptoms',
        url: '',
      },
      {
        label: '자가관리',
        key: 'self-care',
        url: '',
      },
    ];

    const renderList = ENCYCLOPEDIA_SEARCH_RESULT_TAG_LIST.map((tagItem) => {
      switch (tagItem.key) {
        case 'information': {
          return {
            ...tagItem,
            url: `/encyclopedia/diseases/${content.diseaseId}#${tagItem.key}`,
          };
        }
        case 'general-symptoms': {
          return {
            ...tagItem,
            url: content.hasSymptoms
              ? `/encyclopedia/diseases/${content.diseaseId}#${tagItem.key}`
              : '',
          };
        }
        case 'self-care': {
          return {
            ...tagItem,
            url: content.hasSelfCareMethods
              ? `/encyclopedia/diseases/${content.diseaseId}#${tagItem.key}`
              : '',
          };
        }
        default: {
          return {
            ...tagItem,
            url: `/encyclopedia/diseases/${content.diseaseId}`,
          };
        }
      }
    });
    return renderList;
  }
}
