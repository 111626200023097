import {
  AdBannerColorThemeTypeVo,
  AdBannerVo,
  AdTargetPlaceVo,
  ClickActionTypeVo,
} from '@/types/banner';
import {
  GetAllHomeBannersV2Query,
  GetBannerAdExchangeQuery,
} from '@/types/bff/gql-schema';
import { HomeBannerVo } from '@/types/home';

export const parseHomeAdBannerParser = (
  data: GetBannerAdExchangeQuery,
): undefined | AdBannerVo => {
  if (!data.getBannerAdExchange) {
    return undefined;
  }

  return {
    ...data.getBannerAdExchange,
    targetPlace: data.getBannerAdExchange
      .targetPlace as unknown as AdTargetPlaceVo,
    clickAction: {
      ...data.getBannerAdExchange.clickAction,
      clickActionType: data.getBannerAdExchange.clickAction
        .clickActionType as unknown as ClickActionTypeVo,
    },
    colorTheme: data.getBannerAdExchange
      .colorTheme as unknown as AdBannerColorThemeTypeVo,
  } as AdBannerVo;
};

export const parseHomeBannerList = (
  data?: GetAllHomeBannersV2Query,
): Array<HomeBannerVo> => {
  if (!data) {
    return [] as Array<HomeBannerVo>;
  }

  const res = data.allHomeBannersV2;
  return res.map((item) => {
    return {
      ...item,
    } as unknown as HomeBannerVo;
  });
};
