import { useNewestQuestionCardCursorPageQuery } from '@/hooks/qna/qna.gql.impl';
import { mainQnaListParser } from '@/parsers/Communication/qna';

const useGetNewestQuestions = () => {
  const { data } = useNewestQuestionCardCursorPageQuery(
    {
      request: {
        size: 4,
        answered: true,
      },
    },
    {
      select: mainQnaListParser,
    },
  );

  return {
    data,
  };
};

export default useGetNewestQuestions;
