import React from 'react';
import styled from 'styled-components';

import useHomeViewModel from '@/hooks/home/useHomeViewModel';
import theme from '@/styles/theme';
import { ROUTES } from '@/types/common';

const PcMainFeature = () => {
  const viewModel = useHomeViewModel();

  return (
    <Wrapper>
      <Content
        onClick={() => viewModel.handleNavigation(ROUTES.QNA.WRITE.path, 'qna')}
      >
        <p>실시간 의료상담</p>
        <p>
          전문 의료인이
          <br /> 즉시 답해드려요
        </p>
      </Content>
      <Hospital
        onClick={() =>
          viewModel.handleNavigation('https://abr.ge/f9fbpf', 'near_hospital')
        }
      >
        <p>누구나 언제든지</p>
        <p>비대면 진료</p>
      </Hospital>
      <Search
        onClick={() =>
          viewModel.handleNavigation(
            ROUTES.SEARCH().HOME.path,
            'search_disease',
          )
        }
      >
        <p>몸에 이상이 있을 땐</p>
        <p>증상 검색</p>
      </Search>
    </Wrapper>
  );
};

export default PcMainFeature;

const Wrapper = styled.div`
  width: 100%;
  max-width: 768px;
  padding: 0 8px;
  margin-top: 32px;
  display: flex;
  gap: 12px;
`;

const Content = styled.div`
  width: 243px;
  height: 120px;
  border-radius: 12px;
  border: 1px solid ${theme.color.GRAY100};
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.GRAY20};
  background-size: 100% 100%;
  background-image: url('/images/feature1.png');

  & > p:nth-of-type(1) {
    ${theme.typography.UI13M};
    color: ${theme.color.GRAY700};
    opacity: 0.6;
    margin-bottom: 4px;
  }

  & > p:nth-of-type(2) {
    ${theme.typography.UI18SB};
    color: ${theme.color.GRAY700};
  }

  &:hover {
    background-color: ${theme.color.GRAY30};
    cursor: pointer;
  }

  &:active {
    background-color: ${theme.color.GRAY100};
  }
`;

const Hospital = styled(Content)`
  background-color: ${theme.color.PRIMARY700};
  background-size: 100% 100%;
  background-image: url('/images/new-feature2.png');
  border: none;
  & > p:nth-of-type(1) {
    ${theme.typography.UI13M};
    color: ${theme.color.WHITE};
    opacity: 0.6;
  }

  & > p:nth-of-type(2) {
    ${theme.typography.UI18SB};
    color: ${theme.color.WHITE};
  }

  &:hover {
    background-color: ${theme.color.PRIMARY800};
  }

  &:active {
    background-color: ${theme.color.PRIMARY900};
  }
`;

const Search = styled(Hospital)`
  background-image: url('/images/feature3.png');
`;
