import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const useIsResponsive = () => {
  const [isPc, setIsPc] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const mobile = useMediaQuery({
    query: '(max-width:768px)',
  });

  const pc = useMediaQuery({
    query: '(min-width:1024px)',
  });

  useEffect(() => {
    if (mobile) {
      setIsMobile(true);
      setIsTablet(false);
      setIsPc(false);
      return;
    }

    if (pc) {
      setIsTablet(false);
      setIsMobile(false);
      setIsPc(true);
      return;
    }

    setIsTablet(true);
    setIsMobile(false);
    setIsPc(false);
  }, [mobile, pc]);

  return {
    isMobile,
    isTablet,
    isPc,
  };
};

export default useIsResponsive;
