import React, { useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import useGetBannerAdExchange from '@/hooks/advertisement/useGetBannerAdExchange';
import useGetHomeMagazines from '@/hooks/home/useGetHomeMagazines';
import useGetNewestQuestions from '@/hooks/home/useGetNewestQuestions';
import useHomeBannerList from '@/hooks/home/useHomeBannerList';
import useCustomRouter from '@/hooks/useCustomRouter';
import useIsResponsive from '@/hooks/useIsResponsive';
import { recentKeywordListState } from '@/recoil/atoms/qna';
import { HomeSearchService } from '@/services/search/HomeSearchService';
import { AdTargetPlace } from '@/types/bff/gql-schema';
import { ROUTES } from '@/types/common';
import {
  HomeBanner,
  HomeBannerActionUrlType,
  HomeBannerImageUrlType,
} from '@/types/home';
import { coreLogger } from '@/utils/logger';

const useHomeViewModel = () => {
  const { router, pageName } = useCustomRouter();
  const { isMobile } = useIsResponsive();
  const [toggle, setToggle] = useState('qna');
  const qnaRef = useRef<HTMLDivElement | null>(null);
  const magazineRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { data: adBanner } = useGetBannerAdExchange(
    AdTargetPlace.UserWebHomeMain,
  );
  const { homeBannerList } = useHomeBannerList();
  const { magazines } = useGetHomeMagazines();
  const { data: qnaList } = useGetNewestQuestions();
  const [bannerIndex, setBannerIndex] = useState(0);
  const [recentKeywordList, setRecentKeywordList] = useRecoilState(
    recentKeywordListState,
  );

  const bannerList: HomeBanner[] = useMemo(() => {
    return homeBannerList
      .filter(
        (banner) =>
          banner.actionUrls.filter(
            (item) =>
              item.type === HomeBannerActionUrlType.WEB &&
              item.url &&
              item.url.includes('checker'),
          ).length === 0,
      )
      .map((banner) => {
        return {
          ...banner,
          imageUrl: isMobile
            ? banner.imageUrls.filter(
                (url) => url.type === HomeBannerImageUrlType.MOBILE,
              )[0].url ?? ''
            : banner.imageUrls.filter(
                (url) => url.type === HomeBannerImageUrlType.PC,
              )[0].url ?? '',
          actionUrl:
            banner.actionUrls.filter(
              (url) => url.type === HomeBannerActionUrlType.WEB,
            )[0].url ?? '',
        };
      });
  }, [homeBannerList, isMobile]);

  const onClickSearchIcon = () => {
    coreLogger({
      pageName,
      eventName: 'click_search_keyword_icon',
    });

    if (inputRef.current) {
      router.push(`${ROUTES.SEARCH().HOME.path}`);
    }
  };

  const clickQnaTab = () => {
    if (qnaRef.current) {
      qnaRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    coreLogger({
      pageName,
      eventName: 'click_qna_tab',
    });
    setToggle('qna');
  };

  const clickMagazineTab = () => {
    if (magazineRef.current) {
      magazineRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    coreLogger({
      pageName,
      eventName: 'click_magazine_tab',
    });
    setToggle('magazine');
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter' || e.keyCode === 13) {
      const keyword = e.currentTarget.value.trim();
      coreLogger({
        pageName,
        eventName: 'search_keyword',
      });
      const newKeywordList = HomeSearchService.getNewKeywordList(
        [...recentKeywordList],
        keyword,
      );

      setRecentKeywordList(newKeywordList);
      router.push(
        `${ROUTES.SEARCH(keyword.replaceAll(' ', '+')).SEARCH_RESULT.path}`,
      );
    }
  };

  const handleNavigation = (path: string, service: string) => {
    coreLogger({
      pageName,
      eventName: `click_${service}_banner`,
    });
    router.push(path);
  };

  const handleOutLink = (path: string, bannerId: string) => {
    coreLogger({
      pageName,
      eventName: `click_top_banner`,
      payload: {
        notice_id: bannerId,
      },
    });
    window.open(path);
  };

  const goToMagazineDetail = (magazinePid: string) => {
    coreLogger({
      pageName,
      eventName: 'click_magazine_item',
      payload: {
        magazinePid,
      },
    });
    router.push(`${ROUTES.MAGAZINE.DETAIL.path}/${magazinePid}`);
  };

  const goToMagazineList = () => {
    coreLogger({
      pageName,
      eventName: 'click_magazine_more',
    });
    router.push(`${ROUTES.MAGAZINE.HOME.path}`);
  };

  const goToQnaDetail = (questionPid: string) => {
    coreLogger({
      pageName,
      eventName: 'click_qna_item',
      payload: {
        questionPid,
      },
    });
    router.push(`${ROUTES.QNA.DETAIL.path}/${questionPid}`);
  };

  const goToQnaList = () => {
    coreLogger({
      pageName,
      eventName: 'click_qna_more',
    });
    router.push(`${ROUTES.QNA.REALTIME.path}`);
  };

  const onChangeBannerIndex = (index: number) => {
    setBannerIndex(index);
  };

  const handleDeleteItemRecentKeyword = (keyword: string) => {
    const filter = recentKeywordList.filter(
      (recentKeyword) => keyword !== recentKeyword,
    );

    setRecentKeywordList(filter);
  };

  const allDeleteRecentKeyword = () => {
    setRecentKeywordList([]);
  };

  const moveSearchKeyword = (keyword: string) => {
    router.push(
      `${
        ROUTES.SEARCH(keyword.trim().replaceAll(' ', '+')).SEARCH_RESULT.path
      }`,
    );
  };

  return {
    toggle,
    qnaRef,
    magazineRef,
    inputRef,
    isMobile,
    adBanner,
    magazines: magazines ?? [],
    qnaList: qnaList ?? [],
    bannerList,
    bannerIndex,
    recentKeywordList,
    clickQnaTab,
    clickMagazineTab,
    handleKeyUp,
    handleNavigation,
    handleOutLink,
    goToMagazineDetail,
    goToMagazineList,
    goToQnaDetail,
    goToQnaList,
    onClickSearchIcon,
    onChangeBannerIndex,
    handleDeleteItemRecentKeyword,
    moveSearchKeyword,
    allDeleteRecentKeyword,
  };
};

export default useHomeViewModel;
