import React from 'react';
import styled from 'styled-components';

import { Tablet } from '@/styles/layout';
import { resizingImg } from '@/utils/image';

interface Props {
  profileImage: string[];
  width?: number;
  height?: number;
}
const AnswerDoctorImages = ({
  profileImage,
  width = 32,
  height = 32,
}: Props) => {
  return (
    <div>
      {profileImage.map((img, index) => (
        <AnswerDoctorImage
          key={index}
          src={resizingImg({ originUrl: img, width: 64, height: 64 })}
          alt="의사 이미지"
          index={index}
          width={width}
          height={height}
        />
      ))}
    </div>
  );
};

export default AnswerDoctorImages;

const AnswerDoctorImage = styled.img<{
  index: number;
  width: number;
  height: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border: 2px solid ${({ theme }) => theme.color.WHITE};
  border-radius: 32px;
  position: relative;
  z-index: ${({ index }) => index};
  object-fit: cover;

  &:not(:first-child) {
    margin-left: -8px;
  }

  @media screen and (max-width: ${Tablet}) {
    width: 28px;
    height: 28px;
  }
`;
