import theme from '@/styles/theme';

interface Props {
  size?: number;
  fill?: string;
}

const DoctornowCi = ({ size = 64, fill = theme.color.PRIMARY500 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.46"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3638 32C44.3638 29.59 42.4101 27.6364 40.0001 27.6364L37.091 27.6364L37.091 24.7273C37.091 22.3173 35.1374 20.3636 32.7274 20.3636C30.3174 20.3636 28.3638 22.3173 28.3638 24.7273L28.3638 32C28.3638 34.4006 30.3022 36.3484 32.6992 36.3636C32.7086 36.3636 32.718 36.3636 32.7274 36.3636L40.0001 36.3636C42.4101 36.3636 44.3638 34.41 44.3638 32Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0911 32C21.0911 34.41 23.0447 36.3636 25.4547 36.3636H28.3638L28.3638 39.2727C28.3638 41.6827 30.3175 43.6364 32.7274 43.6364C35.1374 43.6364 37.0911 41.6827 37.0911 39.2727L37.0911 32C37.0911 29.5994 35.1526 27.6516 32.7556 27.6364C32.7462 27.6364 32.7368 27.6364 32.7274 27.6364H25.4547C23.0447 27.6364 21.0911 29.59 21.0911 32Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2728 2.90912H16.1079C10.6259 2.90912 6.18193 7.35311 6.18193 12.835V16.4549C4.63914 17.5789 3.63647 19.3996 3.63647 21.4546C3.63647 24.8687 6.40417 27.6364 9.81829 27.6364C13.2324 27.6364 16.0001 24.8687 16.0001 21.4546C16.0001 19.2423 14.838 17.3015 13.091 16.2092V12.835C13.091 11.1689 14.4417 9.81821 16.1079 9.81821H31.2728C43.7243 9.81821 53.8183 19.9122 53.8183 32.3637C53.8183 44.8152 43.7243 54.9091 31.2728 54.9091H16.1079C14.4417 54.9091 13.091 53.5584 13.091 51.8923V48.5182C14.838 47.4259 16.0001 45.485 16.0001 43.2728C16.0001 39.8586 13.2324 37.0909 9.81829 37.0909C6.40417 37.0909 3.63647 39.8586 3.63647 43.2728C3.63647 45.3277 4.63914 47.1484 6.18193 48.2725V51.8923C6.18193 57.3742 10.6259 61.8182 16.1079 61.8182H31.2728C47.5401 61.8182 60.7274 48.631 60.7274 32.3637C60.7274 16.0964 47.5401 2.90912 31.2728 2.90912Z"
        fill={fill}
      />
    </svg>
  );
};

export default DoctornowCi;
