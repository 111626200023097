import { GetMyQuestionCardCursorPageQuery } from '@/types/bff/gql-schema';
import {
  AttachmentBlindType,
  NewestQuestionCardCursorPageQuery,
} from '@/types/bff/gql-schema';
import { HomeQnaItemVo } from '@/types/home';
import { RealtimeAnswerStatusTypeVo } from '@/types/qna';

export const parseRealtimeAnswerStatus = (
  data: GetMyQuestionCardCursorPageQuery | undefined,
): RealtimeAnswerStatusTypeVo | null => {
  if (!data || data.myQuestionCardCursorPage.elements.length === 0) {
    return null;
  }

  return {
    content: data.myQuestionCardCursorPage.elements[0].question.content,
    questionPid: data.myQuestionCardCursorPage.elements[0].question.questionPid,
    answeredStatus: data.myQuestionCardCursorPage.elements[0].answeredStatus,
  };
};

export const mainQnaListParser = (
  data: NewestQuestionCardCursorPageQuery,
): Array<HomeQnaItemVo> => {
  return data.newestQuestionCardCursorPage.elements.map((question) => {
    return {
      questionPid: question.question.questionPid,
      title: question.question.title,
      content: question.question.content,
      answererCount: question.answererCount,
      answererProfileImageUrls: question.answererProfileImageUrls,
      attachment: question.question.attachments.filter(
        (attachment) => attachment.blindType !== AttachmentBlindType.Blinded,
      )[0],
    };
  });
};
