import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { EllipsisTextStyle } from '@/components/atom/text/DefaultText';
import useHomeViewModel from '@/hooks/home/useHomeViewModel';
import { Tablet } from '@/styles/layout';
import theme from '@/styles/theme';

const MainMagazineList = forwardRef<HTMLDivElement>((_, ref) => {
  const viewModel = useHomeViewModel();
  return (
    <Wrapper ref={ref}>
      <Header>
        <h2>건강 매거진</h2>
        <button type="button" onClick={viewModel.goToMagazineList}>
          더보기
        </button>
      </Header>
      <Content>
        {viewModel.magazines.map((magazine) => (
          <Card
            key={magazine.magazinePid}
            onClick={() => viewModel.goToMagazineDetail(magazine.magazinePid)}
          >
            <Info>
              <h3>{magazine.title}</h3>
              <p>{magazine.date}</p>
            </Info>
            <Image src={magazine.image} alt="이미지" />
          </Card>
        ))}
      </Content>
    </Wrapper>
  );
});

MainMagazineList.displayName = 'MainMagazineList';
export default MainMagazineList;

const Wrapper = styled.div`
  max-width: 768px;
  width: 100%;

  @media screen and (max-width: ${Tablet}) {
    padding: 0 12px;
  }
`;

const Header = styled.div`
  display: flex;
  padding: 0 12px;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 11px;

  @media screen and (max-width: ${Tablet}) {
    margin-bottom: 8px;
  }

  & > button {
    padding: 0 10px;
    border-radius: 8px;
    height: 32px;
    &:hover {
      background-color: ${({ theme }) => theme.color.GRAY30};
    }

    &:active {
      background-color: ${({ theme }) => theme.color.GRAY100};
    }
  }

  & > h2 {
    ${theme.typography.UI19SB};
    color: ${theme.color.GRAY700};
  }

  & > button {
    border: none;
    ${theme.typography.UI14M};
    color: ${theme.color.GRAY300};
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 28px;

  @media screen and (max-width: ${Tablet}) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 12px;
  border-radius: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.color.GRAY30};
  }

  &:active {
    background-color: ${({ theme }) => theme.color.GRAY100};
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    ${theme.typography.UI16SB};
    color: ${theme.color.GRAY700};
    margin-bottom: 8px;
    ${EllipsisTextStyle};
  }

  & > p {
    ${theme.typography.UI13M};
    color: ${theme.color.GRAY300};
  }
`;

const Image = styled.img`
  border-radius: 12px;
  width: 80px;
  height: 80px;
  margin-left: 16px;
  object-fit: cover;
`;
