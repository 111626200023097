export const BannerType = {
  DOWNLOAD: 'Download',
  SHARE: 'Share',
} as const;

export type BannerType = typeof BannerType[keyof typeof BannerType];

export enum ClickActionTypeVo {
  Outlink = 'OUTLINK',
  PhoneCall = 'PHONE_CALL',
}
export enum AdTargetPlaceVo {
  DoctorWebSideBigLeft = 'DOCTOR_WEB_SIDE_BIG_LEFT',
  DoctorWebSideBigRight = 'DOCTOR_WEB_SIDE_BIG_RIGHT',
  DoctorWebSideSmall = 'DOCTOR_WEB_SIDE_SMALL',
  PharmacistWebSideBig = 'PHARMACIST_WEB_SIDE_BIG',
  PharmacistWebSideSmall = 'PHARMACIST_WEB_SIDE_SMALL',
  UserAppDiagnosisHistoryInList = 'USER_APP_DIAGNOSIS_HISTORY_IN_LIST',
  UserAppDoctorListDepartmentInList = 'USER_APP_DOCTOR_LIST_DEPARTMENT_IN_LIST',
  UserAppDoctorListDepartmentTop = 'USER_APP_DOCTOR_LIST_DEPARTMENT_TOP',
  UserAppDoctorListTreatmentInList = 'USER_APP_DOCTOR_LIST_TREATMENT_IN_LIST',
  UserAppDoctorListTreatmentTop = 'USER_APP_DOCTOR_LIST_TREATMENT_TOP',
  UserAppExit = 'USER_APP_EXIT',
  UserAppHealthDocument = 'USER_APP_HEALTH_DOCUMENT',
  UserAppHealthMain = 'USER_APP_HEALTH_MAIN',
  UserAppHomeMain = 'USER_APP_HOME_MAIN',
  UserAppQnaMain = 'USER_APP_QNA_MAIN',
  UserWebHomeMain = 'USER_WEB_HOME_MAIN',
}

export enum AdBannerColorThemeTypeVo {
  Light = 'LIGHT',
  Dark = 'Dark',
}
export interface AdBannerVo {
  bannerImageUrl: string;
  bannerAdId: string;
  targetPlace: AdTargetPlaceVo;
  deliberatedCode?: string | null;
  title?: string | null;
  subTitle?: string | null;
  longTitle?: string | null;
  longSubTitle?: string | null;
  clickAction: {
    clickActionType: ClickActionTypeVo;
    outlink?: string | null;
    phoneNumber?: string | null;
  };
  colorTheme: AdBannerColorThemeTypeVo;
}
