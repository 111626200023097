import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { EllipsisTextStyle } from '@/components/atom/text/DefaultText';
import AnswerDoctorImages from '@/components/molecules/AnswerDoctorImages';
import PresignedImage from '@/components/molecules/PresignedImage';
import useHomeViewModel from '@/hooks/home/useHomeViewModel';
import { Tablet } from '@/styles/layout';
import theme from '@/styles/theme';
import { AttachmentBlindType } from '@/types/community';

const MainQnaList = forwardRef<HTMLDivElement>((_, ref) => {
  {
    const viewModel = useHomeViewModel();
    return (
      <Wrapper ref={ref}>
        <Header>
          <h2>실시간 상담</h2>
          <button type="button" onClick={viewModel.goToQnaList}>
            더보기
          </button>
        </Header>
        <Content>
          {viewModel.qnaList.map((question) => (
            <Card
              key={question.questionPid}
              onClick={() => viewModel.goToQnaDetail(question.questionPid)}
            >
              <Info>
                <div>
                  <h3>{question.title}</h3>
                  <p>{question.content}</p>
                </div>
                {question.attachment && (
                  <DimmedImage
                    width={80}
                    height={80}
                    attachmentId={question.attachment.questionAttachmentId}
                    dimmed={
                      question.attachment.blindType ===
                      AttachmentBlindType.Dimmed
                    }
                  />
                )}
              </Info>
              <Answer>
                <AnswerDoctorImages
                  width={24}
                  height={24}
                  profileImage={question.answererProfileImageUrls}
                />
                <p>{question.answererProfileImageUrls.length}개의 답변</p>
              </Answer>
            </Card>
          ))}
        </Content>
      </Wrapper>
    );
  }
});

MainQnaList.displayName = 'MainQnaList';
export default MainQnaList;

const Wrapper = styled.div`
  max-width: 768px;
  width: 100%;

  @media screen and (max-width: ${Tablet}) {
    padding: 0 12px;
  }
`;

const Header = styled.div`
  display: flex;
  padding: 0 12px;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 11px;

  @media screen and (max-width: ${Tablet}) {
    margin-bottom: 8px;
  }

  & > button {
    padding: 0 10px;
    border-radius: 8px;
    height: 32px;
    &:hover {
      background-color: ${({ theme }) => theme.color.GRAY30};
    }

    &:active {
      background-color: ${({ theme }) => theme.color.GRAY100};
    }
  }
  & > h2 {
    ${theme.typography.UI19SB};
    color: ${theme.color.GRAY700};
  }

  & > button {
    border: none;
    ${theme.typography.UI14M};
    color: ${theme.color.GRAY300};
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 28px;

  @media screen and (max-width: ${Tablet}) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
  border-radius: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.color.GRAY30};
  }

  &:active {
    background-color: ${({ theme }) => theme.color.GRAY100};
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;

  & > div {
    overflow-wrap: anywhere;

    & > h3 {
      ${theme.typography.UI16SB};
      color: ${theme.color.GRAY700};
      margin-bottom: 8px;
      ${EllipsisTextStyle};
      -webkit-line-clamp: 1;
    }

    & > p {
      ${theme.typography.UI14R};
      color: ${theme.color.GRAY500};
      ${EllipsisTextStyle};
    }
  }
`;

const DimmedImage = styled(PresignedImage)<{ dimmed: boolean }>`
  border-radius: 12px;
  object-fit: cover;
  margin-left: 16px;
  ${({ dimmed }) => dimmed && `filter: brightness(0.40) blur(2px)`};
`;

const Answer = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  & > div {
    margin-right: 8px;
  }

  & > p {
    ${theme.typography.UI13M};
    color: ${theme.color.GRAY300};
  }
`;
