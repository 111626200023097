import { useGetMagazinesQuery } from '@/hooks/magazine/mobile/magazine.gql.impl';
import { parseHomeMagazines } from '@/parsers/Communication/magazine';

const useGetHomeMagazines = () => {
  const { data: magazines } = useGetMagazinesQuery(
    {
      magazineCursorPageRequestInput: {
        size: 4,
      },
    },
    {
      select: (data) => parseHomeMagazines(data.magazineCursorPage.elements),
    },
  );
  return {
    magazines: magazines ?? [],
  };
};

export default useGetHomeMagazines;
