import { parseHomeBannerList } from '@/parsers/home';
import { BannerDisplayType } from '@/types/bff/gql-schema';

import { useGetAllHomeBannersV2Query } from './home.gql.impl';

const useHomeBannerList = () => {
  const { data: homeBannerList } = useGetAllHomeBannersV2Query(
    {
      request: {
        displayType: BannerDisplayType.Web,
      },
    },
    {
      select: parseHomeBannerList,
    },
  );

  return {
    homeBannerList: homeBannerList ?? [],
  };
};

export default useHomeBannerList;
