import { ImageProps } from 'next/image';
import React from 'react';

import Loader from '@/components/molecules/Loader';
import { useGetQuestionAttachmentPreSignedUrlQuery } from '@/hooks/qna/qna.gql.impl';

interface Props extends Omit<ImageProps, 'src'> {
  attachmentId: string;
  isDownload?: boolean;
}

const PresignedImage = (props: Props) => {
  const { attachmentId, ...imgProps } = props;
  const { data, isLoading, isError } =
    useGetQuestionAttachmentPreSignedUrlQuery({
      questionAttachmentId: attachmentId,
    });

  if (isLoading) return <Loader />;
  if (isError) return <Loader />;
  if (!data.attachmentDownloadPreSignedUrl) return <Loader />;
  return (
    <img {...imgProps} src={data.attachmentDownloadPreSignedUrl} alt="이미지" />
  );
};

export default PresignedImage;
