import { RefObject, useCallback, useEffect, useState } from 'react';

import useWindowFocus from './useWindowFocus';

export const useGetIsInViewElement = (
  ElementRef: RefObject<HTMLDivElement> | null,
  viewRatio = 0.1,
  oneTime = false,
) => {
  const isFocused = useWindowFocus();
  const [isInView, setIsInView] = useState<boolean>(false);

  const onScroll = useCallback(() => {
    if (ElementRef) {
      if (ElementRef.current) {
        const currentScrollBottom = window.innerHeight + window.scrollY;
        const checkVisibleAreaTop =
          ElementRef.current.offsetTop +
          ElementRef.current.clientHeight * viewRatio;

        if (currentScrollBottom >= checkVisibleAreaTop) {
          if (oneTime) {
            window.removeEventListener('scroll', onScroll);
          }
          return setIsInView(true);
        }
        return setIsInView(false);
      }
    }
  }, [ElementRef, viewRatio, oneTime]);

  useEffect(() => {
    isFocused && onScroll();
  }, [isFocused, onScroll]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return [isInView];
};
