import React from 'react';
import styled from 'styled-components';

import useAdBannerViewModel from '@/hooks/home/useAdBannerViewModel';
import theme from '@/styles/theme';
import { AdBannerVo } from '@/types/banner';

interface Props {
  adBanner: AdBannerVo;
}

const AdBanner = ({ adBanner }: Props) => {
  const viewModel = useAdBannerViewModel({ adBanner });
  return (
    <Wrapper ref={viewModel.bannerMainRef}>
      <Banner onClick={viewModel.onPressBanner} bgColor={viewModel.bgColor}>
        <div>
          <Title>{viewModel.title}</Title>
          <SubTitle>{viewModel.subTitle}</SubTitle>
        </div>
        <BannerImage imageUrl={adBanner.bannerImageUrl} />
        <AdMark>AD</AdMark>
      </Banner>
      {adBanner.deliberatedCode && (
        <DeliberatedCode>{adBanner.deliberatedCode}</DeliberatedCode>
      )}
    </Wrapper>
  );
};

export default AdBanner;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${theme.typography.UI12M};
  color: ${theme.color.GRAY300};
`;

const Banner = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  padding: 0 24px;
  border-radius: 16px;
  position: relative;
  ${({ theme, bgColor }) => `
    background: ${bgColor};
    border: 1px solid ${theme.color.GRAY200};
  `};
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

const Title = styled.p`
  ${theme.typography.UI16SB};
  color: ${theme.color.GRAY800};
`;

const SubTitle = styled.p`
  ${theme.typography.UI14M};
  color: ${theme.color.GRAY500};
`;

const AdMark = styled.div`
  ${theme.typography.UI12M};
  color: ${theme.color.WHITE};
  width: 32px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  bottom: 8px;
  right: 16px;
  background-color: ${theme.color.DIMMED40};
`;

const DeliberatedCode = styled.p`
  ${theme.typography.UI12M};
  color: ${theme.color.GRAY300};
  margin-top: 8px;
`;

const BannerImage = styled.div<{ imageUrl: string }>`
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  width: 80px;
  height: 94px;
  position: absolute;
  right: 16px;
`;
