// PageDescription: 닥터나우 홈 페이지
import React from 'react';

import MainTemplate from '@/components/templates/MainTemplate';

const MainPage = () => {
  return (
    <>
      <MainTemplate />
    </>
  );
};

export default MainPage;
