import { useGetBannerAdExchangeQuery } from '@/hooks/advertisement/advertisement.gql.impl';
import { parseHomeAdBannerParser } from '@/parsers/home';
import { AdTargetPlace } from '@/types/bff/gql-schema';

const useGetBannerAdExchange = (adTargetPlace: AdTargetPlace) => {
  const { data } = useGetBannerAdExchangeQuery(
    {
      request: {
        targetPlace: adTargetPlace,
      },
    },
    {
      refetchOnWindowFocus: true,
      select: parseHomeAdBannerParser,
    },
  );

  return { data };
};

export default useGetBannerAdExchange;
