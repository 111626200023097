import styled from 'styled-components';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

import Delete from '@/components/atom/icon/Delete';
import DoctornowCi from '@/components/atom/icon/DoctornowCI';
import HeaderSearch from '@/components/atom/icon/HeaderSearch';
import AdBanner from '@/components/templates/MainTemplate/AdBanner';
import MainMagazineList from '@/components/templates/MainTemplate/MainMagazineList';
import MainQnaList from '@/components/templates/MainTemplate/MainQnaList';
import MobileMainFeature from '@/components/templates/MainTemplate/MobileMainFeature';
import PcMainFeature from '@/components/templates/MainTemplate/PcMainFeature';
import useHomeViewModel from '@/hooks/home/useHomeViewModel';
import { Tablet } from '@/styles/layout';
import theme from '@/styles/theme';

const MainTemplate = () => {
  const viewModel = useHomeViewModel();

  return (
    <Wrapper>
      <h1>대한민국 1위 비대면진료 앱 닥터나우</h1>
      {viewModel.bannerList.length > 0 && (
        <SwiperContainer
          modules={[Autoplay, Navigation]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
          navigation
          onSlideChange={(data) => {
            if (data.realIndex === 1 && data.snapIndex === 0) {
              viewModel.onChangeBannerIndex(0);
              return;
            }
            viewModel.onChangeBannerIndex(data.realIndex);
          }}
        >
          {viewModel.bannerList.map((banner) => {
            return (
              <SwiperSlide key={banner.id}>
                <MainBannerWrapper
                  color={banner.backgroundColor}
                  onClick={() =>
                    viewModel.handleOutLink(banner.actionUrl, banner.id)
                  }
                >
                  <MainBanner image={banner.imageUrl}>
                    <p style={{ color: banner.titleColor }}>{banner.title}</p>
                    <p style={{ color: banner.contentColor }}>
                      {banner.content}
                    </p>
                  </MainBanner>
                </MainBannerWrapper>
              </SwiperSlide>
            );
          })}
        </SwiperContainer>
      )}
      <Content>
        <Indicator>{`${viewModel.bannerIndex + 1} / ${
          viewModel.bannerList.length
        }`}</Indicator>
        <SearchBarWrapper>
          <SearchBar>
            <DoctornowCi size={28} />
            <input
              ref={viewModel.inputRef}
              placeholder="머리가 아파요, 목이 따가워요"
              onKeyUp={viewModel.handleKeyUp}
            />
            <div role="presentation" onClick={viewModel.onClickSearchIcon}>
              <HeaderSearch size={16} fill={theme.color.GRAY700} />
            </div>
          </SearchBar>
          <RecentKeywordWrapper>
            <RecentKeywordAllRemoveButton>
              <p>최근 검색어</p>
              <button type="button" onClick={viewModel.allDeleteRecentKeyword}>
                전체 지우기
              </button>
            </RecentKeywordAllRemoveButton>
            <div>
              <RecentKeywordList>
                {viewModel.recentKeywordList.map((keyword) => {
                  return (
                    <li key={keyword}>
                      <RecentKeywordButton
                        type="button"
                        onClick={() => viewModel.moveSearchKeyword(keyword)}
                      >
                        {keyword}
                      </RecentKeywordButton>
                      <RecentKeywordDeleteButton
                        type="button"
                        onClick={() =>
                          viewModel.handleDeleteItemRecentKeyword(keyword)
                        }
                      >
                        <Delete size={16} color={theme.color.GRAY200} />
                      </RecentKeywordDeleteButton>
                    </li>
                  );
                })}
              </RecentKeywordList>
            </div>
          </RecentKeywordWrapper>
        </SearchBarWrapper>
        {viewModel.isMobile ? <MobileMainFeature /> : <PcMainFeature />}
        <NavigatorTabWrapper>
          <Tab
            isActive={viewModel.toggle === 'qna'}
            onClick={viewModel.clickQnaTab}
          >
            실시간 상담
          </Tab>
          <Tab
            isActive={viewModel.toggle === 'magazine'}
            onClick={viewModel.clickMagazineTab}
          >
            건강 매거진
          </Tab>
        </NavigatorTabWrapper>
        <MainQnaList ref={viewModel.qnaRef} />
        <AdBannerWrapper>
          {viewModel.adBanner && <AdBanner adBanner={viewModel.adBanner} />}
        </AdBannerWrapper>
        <MainMagazineList ref={viewModel.magazineRef} />
      </Content>
    </Wrapper>
  );
};

export default MainTemplate;

const Wrapper = styled.div`
  width: 100%;
  h1 {
    visibility: hidden;
    height: 0;
  }
`;

const MainBannerWrapper = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  background: ${({ color }) => color};
  display: flex;
  justify-content: center;
  padding: 0 24px;
  @media screen and (max-width: ${Tablet}) {
    height: 180px;
    padding: 0 0 0 24px;
  }
`;

const Indicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.typography.UI12M};
  border-radius: 12px;
  color: ${theme.color.WHITE};
  position: absolute;
  top: -74px;
  right: calc((100% - 712px) / 2);
  z-index: 2;
  padding: 2px 8px;
  background: rgba(64, 64, 64, 0.4);

  @media screen and (max-width: ${Tablet}) {
    right: 48px;
  }
`;

const SwiperContainer = styled(Swiper)`
  width: 100%;
  height: 300px;

  @media screen and (max-width: ${Tablet}) {
    height: 180px;
  }

  .swiper-button-prev {
    border-radius: 60px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.1);
    color: ${theme.color.WHITE};
    left: calc((100% - 808px) / 2);

    &:hover {
      opacity: 0.3;
    }

    @media screen and (max-width: ${Tablet}) {
      display: none;
    }

    &::after {
      font-size: unset;
      content: url('/images/icon/chevron_left_24.svg');
    }
  }

  .swiper-button-next {
    border-radius: 60px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.1);
    color: ${theme.color.WHITE};
    right: calc((100% - 808px) / 2);

    &:hover {
      opacity: 0.3;
    }

    @media screen and (max-width: ${Tablet}) {
      display: none;
    }

    &::after {
      font-size: unset;
      content: url('/images/icon/chevron_right_24.svg');
    }
  }
`;

const MainBanner = styled.div<{ image: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 768px;
  width: 100%;
  height: 100%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: 100% 100%;
  color: ${theme.color.WHITE};
  position: relative;

  & > p:first-child {
    ${theme.typography.UI32SB};
    margin-bottom: 16px;
    white-space: pre;
    @media screen and (max-width: ${Tablet}) {
      ${({ theme }) => theme.typography.UI20SB};
      margin-bottom: 10px;
    }
  }
  & > p:last-child {
    ${theme.typography.UI20M};
    opacity: 0.6;

    @media screen and (max-width: ${Tablet}) {
      ${({ theme }) => theme.typography.UI15M};
    }
  }
`;

const Content = styled.main`
  width: 100%;
  background: ${theme.color.WHITE};
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 160px;

  @media screen and (max-width: ${Tablet}) {
    padding-bottom: 80px;
  }
`;

const SearchBarWrapper = styled.div`
  position: absolute;
  max-width: 768px;
  width: 100%;
  display: flex;
  padding: 0 28px;
  height: 64px;
  background: ${theme.color.WHITE};
  border-radius: 40px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  top: -32px;
  align-items: center;
  z-index: 2;

  & > div:last-of-type {
    display: none;
    width: 100%;
  }

  & > div:nth-of-type(2) {
    display: none;
    width: 100%;
    margin-top: 20px;
  }

  &:focus-within {
    height: auto;
    border-radius: 40px;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 28px;

    & > div:last-of-type {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: ${Tablet}) {
    padding: 0 20px;
    max-width: calc(100% - 48px);
  }
`;

const RecentKeywordWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid ${theme.color.GRAY50};
`;

const RecentKeywordAllRemoveButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  & > p {
    ${theme.typography.UI13M};
    color: ${theme.color.GRAY500};
  }

  & > button {
    ${theme.typography.UI14M};
    color: ${theme.color.GRAY300};
    padding: 0 10px;
    height: 32px;
    border-radius: 4px;

    &:hover {
      background-color: ${({ theme }) => theme.color.GRAY30};
    }

    &:active {
      background-color: ${({ theme }) => theme.color.GRAY100};
    }
  }
`;

const RecentKeywordList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > li {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
  }
`;

const RecentKeywordButton = styled.button`
  border: none;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 14px 0;
  ${theme.typography.UI17M};
  color: ${theme.color.GRAY700};
  border-radius: 4px;
`;

const RecentKeywordDeleteButton = styled.button`
  border: none;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  ${theme.typography.UI14M};
`;

const SearchBar = styled.div`
  display: flex;
  width: 100%;

  & > input {
    width: 100%;
    margin: 0 12px;
    height: 28px;
    border: none;
    ${theme.typography.UI18M};

    &::placeholder {
      color: ${theme.color.GRAY200};
    }

    @media screen and (max-width: ${Tablet}) {
      ${theme.typography.UI15M};
    }
  }
`;
const NavigatorTabWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 492px;
  padding: 6px;
  gap: 1px;
  border-radius: 12px;
  background: ${theme.color.GRAY20};
  margin-top: 40px;
  margin-bottom: 32px;

  @media screen and (max-width: ${Tablet}) {
    max-width: calc(100% - 48px);
  }
`;
const Tab = styled.button<{ isActive: boolean }>`
  border: none;
  flex: 1;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ isActive, theme }) =>
    isActive ? theme.color.WHITE : theme.color.GRAY20};
  color: ${({ isActive, theme }) =>
    isActive ? theme.color.GRAY700 : theme.color.GRAY500};
  ${theme.typography.UI15M};
  &:hover {
    background-color: ${({ theme }) => theme.color.GRAY30};
  }

  &:active {
    background-color: ${({ theme }) => theme.color.GRAY100};
  }
`;

const AdBannerWrapper = styled.div`
  width: 100%;
  max-width: 768px;
  padding: 32px 24px;
`;
