import { AttachmentBlindType } from '@/types/community';

export interface HomeQnaItemVo {
  questionPid: string;
  title: string;
  content: string;
  answererCount: number;
  answererProfileImageUrls: Array<string>;
  attachment?: { blindType: AttachmentBlindType; questionAttachmentId: string };
}

export enum HomeBannerActionUrlType {
  WEB = 'WEB',
  APP = 'APP',
}

export enum HomeBannerImageUrlType {
  MOBILE = 'MOBILE',
  PC = 'PC',
}

export interface HomeBannerVo {
  backgroundColor: string;
  content: string;
  contentColor: string;
  id: string;
  imageUrls: Array<{ type: HomeBannerImageUrlType; url?: string | null }>;
  actionUrls: Array<{ type: HomeBannerActionUrlType; url?: string | null }>;
  title: string;
  titleColor: string;
}

export interface HomeBanner {
  backgroundColor: string;
  content: string;
  contentColor: string;
  id: string;
  imageUrl: string;
  actionUrl: string;
  title: string;
  titleColor: string;
}
