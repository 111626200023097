import { useEffect, useRef } from 'react';

import { useSaveAdExposureTraceMutation } from '@/hooks/advertisement/advertisement.gql.impl';
import { useGetIsInViewElement } from '@/hooks/useGetIsInViewElement';
import useIsResponsive from '@/hooks/useIsResponsive';
import theme from '@/styles/theme';
import {
  AdBannerColorThemeTypeVo,
  AdBannerVo,
  ClickActionTypeVo,
} from '@/types/banner';
import { gqlRestRequest } from '@/utils/fetcher';
import {
  NATIVE_POST_MESSAGE_TYPE,
  isAndroid,
  isWebView,
  sendPostMessageToNative,
} from '@/utils/mobile/RNPlugins';

interface Props {
  adBanner: AdBannerVo;
}

const useAdBannerViewModel = ({ adBanner }: Props) => {
  const { isMobile } = useIsResponsive();
  const bannerMainRef = useRef<HTMLDivElement>(null);
  const [isBannerMainInView] = useGetIsInViewElement(bannerMainRef, 0.4, false);
  const title = isMobile ? adBanner.title : adBanner.longTitle;
  const subTitle = isMobile ? adBanner.subTitle : adBanner.longSubTitle;
  const bgColor =
    adBanner.colorTheme === AdBannerColorThemeTypeVo.Light
      ? theme.color.WHITE
      : theme.color.GRAY40;

  const { mutate: sendImpressionLogToServer } =
    useSaveAdExposureTraceMutation();

  const getOs = () => {
    if (!isWebView()) {
      return 'web';
    }
    return isAndroid() ? 'android' : 'ios';
  };

  const sendLogToServer = async () => {
    await gqlRestRequest('v1/ads/click', {
      adId: adBanner.bannerAdId,
      clickedAt: new Date().valueOf(),
      adViewerOsPlatform: getOs(),
    });
  };

  const openOutlink = () => {
    if (adBanner.clickAction.outlink) {
      if (isWebView()) {
        return sendPostMessageToNative(
          NATIVE_POST_MESSAGE_TYPE.OPEN_EXTERNAL_BROWSER,
          {
            url: adBanner.clickAction.outlink,
          },
        );
      }
      window.open(adBanner.clickAction.outlink);
    }
  };

  const openPhoneCall = () => {
    if (adBanner.clickAction.phoneNumber) {
      if (isWebView()) {
        return sendPostMessageToNative(
          NATIVE_POST_MESSAGE_TYPE.OPEN_EXTERNAL_BROWSER,
          {
            url: `tel://${adBanner.clickAction.phoneNumber}`,
          },
        );
      }
      document.location.href = `tel:${adBanner.clickAction.phoneNumber}`;
    }
  };

  const onPressBanner = () => {
    sendLogToServer();
    if (adBanner.clickAction.clickActionType === ClickActionTypeVo.Outlink) {
      openOutlink();
    }
    if (adBanner.clickAction.clickActionType === ClickActionTypeVo.PhoneCall) {
      openPhoneCall();
    }
  };

  useEffect(() => {
    if (isBannerMainInView && adBanner.bannerAdId) {
      sendImpressionLogToServer({
        request: {
          adId: adBanner.bannerAdId,
          exposedAt: new Date().valueOf(),
        },
      });
    }
  }, [isBannerMainInView, adBanner.bannerAdId, sendImpressionLogToServer]);

  return {
    title,
    subTitle,
    bannerMainRef,
    onPressBanner,
    bgColor,
  };
};

export default useAdBannerViewModel;
