import * as Types from '../../../types/bff/gql-schema';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { gqlFetcher } from '@/utils/fetcher';

export const GetMagazinesBannerDocument = `
    query GetMagazinesBanner($magazineCursorPageRequestInput: MagazineCursorPageRequestInput!) {
  magazineCursorPage(request: $magazineCursorPageRequestInput) {
    elements {
      magazinePid
      mobileTitle
      titleImageUrl
    }
  }
}
    `;
export const useGetMagazinesBannerQuery = <
      TData = Types.GetMagazinesBannerQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMagazinesBannerQueryVariables,
      options?: UseQueryOptions<Types.GetMagazinesBannerQuery, TError, TData>
    ) =>
    useQuery<Types.GetMagazinesBannerQuery, TError, TData>(
      ['GetMagazinesBanner', variables],
      gqlFetcher<Types.GetMagazinesBannerQuery, Types.GetMagazinesBannerQueryVariables>(GetMagazinesBannerDocument, variables),
      options
    );

useGetMagazinesBannerQuery.getKey = (variables: Types.GetMagazinesBannerQueryVariables) => ['GetMagazinesBanner', variables];
;

useGetMagazinesBannerQuery.fetcher = (variables: Types.GetMagazinesBannerQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMagazinesBannerQuery, Types.GetMagazinesBannerQueryVariables>(GetMagazinesBannerDocument, variables, options);
export const GetMagazinesDocument = `
    query GetMagazines($magazineCursorPageRequestInput: MagazineCursorPageRequestInput!) {
  magazineCursorPage(request: $magazineCursorPageRequestInput) {
    elements {
      createdAt
      magazinePid
      title
      titleImageUrl
      mobileTitle
      mobileDescription
      description
      author {
        affiliatedInstitutionName
        authorId
        authorType
        externalLinkUrl
        name
        profileImageUrl
        professionalFields {
          name
        }
      }
    }
    pivot
  }
}
    `;
export const useGetMagazinesQuery = <
      TData = Types.GetMagazinesQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMagazinesQueryVariables,
      options?: UseQueryOptions<Types.GetMagazinesQuery, TError, TData>
    ) =>
    useQuery<Types.GetMagazinesQuery, TError, TData>(
      ['GetMagazines', variables],
      gqlFetcher<Types.GetMagazinesQuery, Types.GetMagazinesQueryVariables>(GetMagazinesDocument, variables),
      options
    );

useGetMagazinesQuery.getKey = (variables: Types.GetMagazinesQueryVariables) => ['GetMagazines', variables];
;

useGetMagazinesQuery.fetcher = (variables: Types.GetMagazinesQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMagazinesQuery, Types.GetMagazinesQueryVariables>(GetMagazinesDocument, variables, options);
export const GetMagazineDetailDocument = `
    query getMagazineDetail($magazinePid: ID!) {
  magazineV2(magazinePid: $magazinePid) {
    author {
      affiliatedInstitutionName
      authorId
      authorType
      externalLinkUrl
      name
      profileImageUrl
      professionalFields {
        name
      }
    }
    content
    mobileContent
    createdAt
    tagNames
    description
    likeCount
    magazinePid
    questionCategoryId
    title
    mobileTitle
    mobileDescription
    titleImageUrl
    footnote
    isLikedByMe
    hospital {
      name
      publicId
      roadAddress
      isBookable
      profileImages {
        url
      }
      todayDuty {
        operatingStatus
      }
    }
    bottomButtonType
    tags {
      coreCategories {
        coreId
        coreType
      }
      magazinePid
      name
      tagId
      tagType
    }
    banner {
      id
      magazineId
      externalUrl
      title
      description
      imageUrl
      buttonPhrase
    }
  }
}
    `;
export const useGetMagazineDetailQuery = <
      TData = Types.GetMagazineDetailQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMagazineDetailQueryVariables,
      options?: UseQueryOptions<Types.GetMagazineDetailQuery, TError, TData>
    ) =>
    useQuery<Types.GetMagazineDetailQuery, TError, TData>(
      ['getMagazineDetail', variables],
      gqlFetcher<Types.GetMagazineDetailQuery, Types.GetMagazineDetailQueryVariables>(GetMagazineDetailDocument, variables),
      options
    );

useGetMagazineDetailQuery.getKey = (variables: Types.GetMagazineDetailQueryVariables) => ['getMagazineDetail', variables];
;

useGetMagazineDetailQuery.fetcher = (variables: Types.GetMagazineDetailQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMagazineDetailQuery, Types.GetMagazineDetailQueryVariables>(GetMagazineDetailDocument, variables, options);
export const InsertMagazineLikeDocument = `
    mutation InsertMagazineLike($magazinePid: ID!) {
  insertMagazineLike(magazinePid: $magazinePid)
}
    `;
export const useInsertMagazineLikeMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.InsertMagazineLikeMutation, TError, Types.InsertMagazineLikeMutationVariables, TContext>) =>
    useMutation<Types.InsertMagazineLikeMutation, TError, Types.InsertMagazineLikeMutationVariables, TContext>(
      ['InsertMagazineLike'],
      (variables?: Types.InsertMagazineLikeMutationVariables) => gqlFetcher<Types.InsertMagazineLikeMutation, Types.InsertMagazineLikeMutationVariables>(InsertMagazineLikeDocument, variables)(),
      options
    );
useInsertMagazineLikeMutation.fetcher = (variables: Types.InsertMagazineLikeMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.InsertMagazineLikeMutation, Types.InsertMagazineLikeMutationVariables>(InsertMagazineLikeDocument, variables, options);
export const DeleteMagazineLikeDocument = `
    mutation DeleteMagazineLike($magazinePid: ID!) {
  deleteMagazineLike(magazinePid: $magazinePid)
}
    `;
export const useDeleteMagazineLikeMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteMagazineLikeMutation, TError, Types.DeleteMagazineLikeMutationVariables, TContext>) =>
    useMutation<Types.DeleteMagazineLikeMutation, TError, Types.DeleteMagazineLikeMutationVariables, TContext>(
      ['DeleteMagazineLike'],
      (variables?: Types.DeleteMagazineLikeMutationVariables) => gqlFetcher<Types.DeleteMagazineLikeMutation, Types.DeleteMagazineLikeMutationVariables>(DeleteMagazineLikeDocument, variables)(),
      options
    );
useDeleteMagazineLikeMutation.fetcher = (variables: Types.DeleteMagazineLikeMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.DeleteMagazineLikeMutation, Types.DeleteMagazineLikeMutationVariables>(DeleteMagazineLikeDocument, variables, options);
export const GetRandomMagazinesDocument = `
    query GetRandomMagazines($randomMagazineRequestInput: RandomMagazineRequestInput!) {
  randomMagazines(request: $randomMagazineRequestInput) {
    createdAt
    magazinePid
    titleImageUrl
    mobileTitle
    mobileDescription
    author {
      authorId
    }
  }
}
    `;
export const useGetRandomMagazinesQuery = <
      TData = Types.GetRandomMagazinesQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetRandomMagazinesQueryVariables,
      options?: UseQueryOptions<Types.GetRandomMagazinesQuery, TError, TData>
    ) =>
    useQuery<Types.GetRandomMagazinesQuery, TError, TData>(
      ['GetRandomMagazines', variables],
      gqlFetcher<Types.GetRandomMagazinesQuery, Types.GetRandomMagazinesQueryVariables>(GetRandomMagazinesDocument, variables),
      options
    );

useGetRandomMagazinesQuery.getKey = (variables: Types.GetRandomMagazinesQueryVariables) => ['GetRandomMagazines', variables];
;

useGetRandomMagazinesQuery.fetcher = (variables: Types.GetRandomMagazinesQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetRandomMagazinesQuery, Types.GetRandomMagazinesQueryVariables>(GetRandomMagazinesDocument, variables, options);
export const GetMagazinesCurationDocument = `
    query GetMagazinesCuration($magazineCursorPageRequestInput: MagazineCursorPageRequestInput!) {
  magazineCursorPage(request: $magazineCursorPageRequestInput) {
    elements {
      magazinePid
      mobileTitle
      mobileDescription
      titleImageUrl
      createdAt
    }
  }
}
    `;
export const useGetMagazinesCurationQuery = <
      TData = Types.GetMagazinesCurationQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMagazinesCurationQueryVariables,
      options?: UseQueryOptions<Types.GetMagazinesCurationQuery, TError, TData>
    ) =>
    useQuery<Types.GetMagazinesCurationQuery, TError, TData>(
      ['GetMagazinesCuration', variables],
      gqlFetcher<Types.GetMagazinesCurationQuery, Types.GetMagazinesCurationQueryVariables>(GetMagazinesCurationDocument, variables),
      options
    );

useGetMagazinesCurationQuery.getKey = (variables: Types.GetMagazinesCurationQueryVariables) => ['GetMagazinesCuration', variables];
;

useGetMagazinesCurationQuery.fetcher = (variables: Types.GetMagazinesCurationQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMagazinesCurationQuery, Types.GetMagazinesCurationQueryVariables>(GetMagazinesCurationDocument, variables, options);
export const GetKeywordSearchMagazineCursorPageDocument = `
    query GetKeywordSearchMagazineCursorPage($request: KeywordSearchMagazineCursorPageRequestInput!) {
  keywordSearchMagazineCursorPage(request: $request) {
    elements {
      createdAt
      magazinePid
      title
      description
      titleImageUrl
      mobileTitle
      mobileDescription
      content
      author {
        affiliatedInstitutionName
        authorId
        authorType
        externalLinkUrl
        name
        profileImageUrl
        professionalFields {
          name
        }
      }
    }
    pivot
  }
}
    `;
export const useGetKeywordSearchMagazineCursorPageQuery = <
      TData = Types.GetKeywordSearchMagazineCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetKeywordSearchMagazineCursorPageQueryVariables,
      options?: UseQueryOptions<Types.GetKeywordSearchMagazineCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetKeywordSearchMagazineCursorPageQuery, TError, TData>(
      ['GetKeywordSearchMagazineCursorPage', variables],
      gqlFetcher<Types.GetKeywordSearchMagazineCursorPageQuery, Types.GetKeywordSearchMagazineCursorPageQueryVariables>(GetKeywordSearchMagazineCursorPageDocument, variables),
      options
    );

useGetKeywordSearchMagazineCursorPageQuery.getKey = (variables: Types.GetKeywordSearchMagazineCursorPageQueryVariables) => ['GetKeywordSearchMagazineCursorPage', variables];
;

useGetKeywordSearchMagazineCursorPageQuery.fetcher = (variables: Types.GetKeywordSearchMagazineCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetKeywordSearchMagazineCursorPageQuery, Types.GetKeywordSearchMagazineCursorPageQueryVariables>(GetKeywordSearchMagazineCursorPageDocument, variables, options);